import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { BsBriefcase } from "react-icons/bs"; // Importing an icon for the experience card
import { EXPERIENCE } from "../../Constants"; // Your JSON data imported here
import "./Experience.css";
function Experience() {
  return (
    <Container fluid className="experience-section bg-light">
      <Container>
        <Row className="justify-content-center">
          <h1 className="project-heading mt-5">
            My Personal <strong className="purple">Experience </strong>
          </h1>

          {EXPERIENCE.map((exp, index) => (
            <Col key={index} xs={12} md={10} lg={8} className="mb-4">
              <Card className="experience-card shadow">
                <Card.Header
                  as="h5"
                  className="bg-dark text-white d-flex justify-content-center align-items-center"
                >
                  <BsBriefcase
                    className="me-2"
                    style={{ marginRight: "1rem" }}
                  />
                  {exp.company}
                </Card.Header>

                <Card.Body>
                  <Card.Title>{exp.vitri}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {exp.thoigian}
                  </Card.Subtitle>
                  <Card.Text>
                    {exp.mota.split(";").map((line, index) => (
                      <React.Fragment key={index}>
                        {line.trim()}.
                        {index !== exp.mota.split(";").length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>

  );
}

export default Experience;
