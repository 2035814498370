import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/profile.jpg";
import TypeWriter from "./TypeWriter";
import Footer from "../Footer";

function Home() {
  return (
    <section className="flex flex-col min-h-screen">
      <Container fluid className="home-section" id="home" style={{ height: '100vh' }}>
        <Container className="home-content" style={{ height: '100%' }}>
          <Row className="align-items-center" style={{ height: '100%' }}>
            <Col md={6} className="home-header" style={{ height: '100%' }}>
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> Phạm Đức Đạt</strong>
              </h1>

              <p className="heading-description blockquote">
                I am a fourth-year student majoring in Computer Science at the International University (IU) - VNU, Vietnam National University - Ho Chi Minh City. I have knowledge in Frontend Developer, Backend Developer, and I am currently researching AI. I am seeking opportunities to work in a challenging environment that provides room for personal growth.
              </p>

              <div className="heading-type">
                <TypeWriter />
              </div>
            </Col>

            <Col md={5} style={{ height: '100%' }}>
              <img src={myImg} className="profile-pic" alt="avatar" style={{ maxHeight: '100%', objectFit: 'contain' }} />
            </Col>
          </Row>
        </Container>
      </Container>

      <Footer isFixed={true} />
    </section>
  );
}

export default Home;
